import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className="title has-text-weight-bold">HMM Ventures</h1>
    <p className="mb-4 is-family-secondary">Imported Fine Wood Boxes and Gifts</p>
    <p className="mb-4 is-family-secondary">Coming Soon!! -  Opening January 2021</p>
    <div className="columns">
      <div className="column is-half">
        <Image fileName="single-wood-wine-box-with-logo.jpg" alt="Single wood wine box with logo"/>
      </div>
      <div className="column is-half">
        <Image fileName="wood-gift-box-with-logo.jpg" alt="wood gift box with logo"/>
      </div>
    </div>
  </Layout>
)

export default IndexPage
